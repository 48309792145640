import React, { lazy, Suspense, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'
import { useScrollPercentage } from 'react-scroll-percentage'
import { useQueryParam, ArrayParam } from 'use-query-params'

import { placesToSchema } from 'components/utils/schemaUtils.js'

import SEO from 'components/seo'
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Footer from "components/footer"

import ActivitiesBlock from 'components/post/blocks/activitiesBlock'
const Filters = lazy(() => import('components/filters/Filters'))
const FloatingAction = lazy(() => import('components/modal/floatingAction'))

import "styles/pages.scss"

const ActivityPage = (props) => {

  const {
    numberOfCards,
    pageContext,
  } = props

  const {
    activity,
    city,
    cityDetails,
    pageDetails,
    places,
    vibes
  } = pageContext

  const { description, details } = pageDetails

  const selectedCity = cityDetails
  const cityName = cityDetails.title

  const descriptionText = description
    ? description
        .replace('${city}', cityName)
        .replace('%city%', cityName)
      : null

  const initialSchemaData = (places && places.length > 0)
    ? placesToSchema(places)
    : []

  const [schemaPlaces, setSchemaPlaces] = useState(initialSchemaData)

  const [currentActivity, setCurrentActivity] = useState(activity)
  const [currentVibes, setCurrentVibes] = useQueryParam('vibes', ArrayParam)
  const [currentSearch, setCurrentSearch] = useState(null)

  useEffect(() => {
    const { details } = pageDetails
    if (details.searchTerm) setCurrentSearch(details.searchTerm)
  }, [])

  // TODO: use zustand and reduce 1 render
  const [isClient, setClient] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [hasSections, setHasSections] = useState(false) // TODO: Set default from SSR data

  const [ref, percentage] = useScrollPercentage({ threshold: 0.0, })

  const onScroll = (e) => {
    setHasScrolled(true)
  }

  useEffect(() => {
    if (hasScrolled && percentage > 0.2) {
      setShowActions(true)
    }
  }, [percentage, hasScrolled])

  // Handle filter changes
  const onChange = (newFilters) => {
    //console.log('onChange ', newFilters)
    //setFilters(newFilters)

    const selectedActivity = newFilters.activities[0] ? newFilters.activities[0] : ''
    const selectedVibes = newFilters.vibes

    const hasChanged = activity !== selectedActivity

    if (selectedActivity && hasChanged) {
      setCurrentActivity(selectedActivity)
      const nextURL = `/cities/${city}/activities/${selectedActivity}/${window.location.search}`
      window.location.assign(nextURL);
    }

    if (selectedVibes) {
      //setCurrentVibes(selectedVibes)
      setVibesTop(vibes)
    }
  }

  const handleAction = (action) => {
    console.log('handleAction ', action)
    switch (action.key) {
      case 'add':
        navigate('/add-place')
        break
      default:
        break
    }
  }

  const handlePlaces = (places) => {
    // Handle any places in a callback
    const schemaData = placesToSchema(places)
    setSchemaPlaces(schemaData)
  }

  // Create SEO
  const seoData = pageDetails.seo
    ? pageDetails.seo
    : {}

  const activityName = pageDetails?.details?.noun

  const shortTitle = `${activityName
    ? activityName
    : pageDetails.title}`

  const title = `${shortTitle} ${selectedCity ? ' | ' + selectedCity.title : 'near you'}`

  const titleImpact = `${selectedCity?.title ? selectedCity?.title + ' ' : ''}${shortTitle}`
  seoData.title = title

  const vibeset = details && details.vibeset
    ? details.vibeset[0]
    : null

  const impactAreaData = {
    impactArea: {
      backgroundColor: 'grey',
      backgroundType: 'vibe_static',
      //city: selectedCity ? selectedCity : null,
      heading: titleImpact,
      hideImpactArea: null,
      vibeset: vibeset
    }
  }

  return (
    <div className={'page activities'} ref={ref}>
      <TemplateContextProvider
        activity={activity}
        city={cityDetails}>
        <PlacesContextProvider initalPlaces={places}>
          <SEO
            description={seoData.metaDesc}
            lang="en-US"
            title={`${title} | Vibemap `}
            data={seoData}
            schemaData={schemaPlaces}
          />
          <main>
            <Header
              city={city}
              citySelected={true} />

            {isClient && showActions &&
              <Suspense fallback={<span>...</span>}>
                <FloatingAction handleAction={handleAction} />
              </Suspense>
            }

            <ImpactArea data={impactAreaData} />

            <div className='post-body'>
              <Suspense>
                <Filters
                  activity={currentActivity}
                  page={'activities'}
                  showActivities={true}
                  showCities={false}
                  showDates={false}
                  showVibes={true}
                  onChange={onChange} />
              </Suspense>

              <section className='post-container'>
                <div className='container'>
                  <div className='narrow description'>
                    <p>
                      {descriptionText ? descriptionText : seoData.metaDesc}
                    </p>
                  </div>

                </div>
                <div className={`wp-block acf-sing-cards ${!hasSections ? 'style-list' : ''}`}>
                  <div className={`heading container`}>
                    <h3>Top Vibes</h3>
                  </div>

                  <ActivitiesBlock
                    activity={currentActivity}
                    city={city}
                    searchTerm={currentSearch}
                    vibes={currentVibes}
                    placesCallback={handlePlaces}
                    />

                </div>
              </section>

            </div>
          </main>
          <Footer />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}

ActivityPage.defaultProps = {
  numberOfCards : 40,
  showActions: true
}

export default ActivityPage
