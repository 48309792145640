import React, { lazy, Suspense, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { pure } from 'recompose'

import { useTemplateContext } from 'components/post/TemplateProvider'
import { usePlacesContext } from 'components/post/PlacesProvider'

import CardSkeletons from "components/post/singCard/cardSkeletons"
const LoadApiSingCard = lazy(() => import('components/post/singCard/apiCard'))
//const Slider = lazy(() => import('components/slider'))

import { placesToCards } from 'components/post/blocks/singCardsBlock/placesToCards.js'
import { placesToSchema } from 'components/utils/schemaUtils.js'

import {
  fetchPlacePicks,
  getTopCategories,
  getTopVibes,
} from 'vibemap-constants/dist/helpers'

function ActivitiesBlock({
  activity,
  city,
  vibes,
  searchTerm,
  numberOfCards,
  placesCallback }) {

  const pageContext = useTemplateContext()
  const { currentCity, getCity, filters, setFilters } = pageContext

  const placesContext = usePlacesContext()
  const { places } = placesContext

  const topVibes = getTopVibes(places)
  const topCategories = getTopCategories(places)

  const initialCards = (places && places.length > 0)
    ? placesToCards(places.slice(0, numberOfCards))
    : []

  const cardsPreloaded = initialCards.length > 0

  const [isLoading, setIsLoading] = useState(!cardsPreloaded) // TODO: Set default from SSR data
  const [cards, setCards] = useState(initialCards) // TODO: Set default from SSR data
  const [cardOptions, setCardOptions] = useState({}) // TODO: Set default from SSR data

  // Hydrate places and events data on page load
  useDeepCompareEffect(() => {
    (async () => {
      // If no result, selectedCity is undefined, which works for the query options
      const selectedCity = await getCity(city)

      // Default queries; override by filter
      let activityQuery = activity
      let searchQuery = searchTerm ? searchTerm : null
      let vibeQuery = vibes ? vibes : null
      let geoQuery = selectedCity?.location
        ? selectedCity?.location
        : selectedCity?.cityDetails
          ? selectedCity?.cityDetails?.placemarker
          : null

      let options = {
        activity: activityQuery,
        category: activityQuery == 'all' ? null : activityQuery,
        distance: 10,
        per_page: 300,
        point: selectedCity?.centerpoint?.join(','),
        ordering: '-score_combined',
        search: searchQuery ? searchQuery : null,
        shouldShuffle: true,
        vibes: vibeQuery
      }

      // TODO: Handle one or more sections
      const hasCards = cards.length > 0
      const hasFilters = searchQuery && searchQuery.length || vibeQuery && vibeQuery.length > 0

      if (hasCards && !hasFilters) return false

      setIsLoading(true)

      fetchPlacePicks(options)
        .then(response => {
          const topPlaces = response.data.slice(0, numberOfCards)
          const cards = placesToCards(topPlaces)
          const schemaData = placesToSchema(cards)
          placesCallback(topPlaces)
          setCards(cards)
          setIsLoading(false)
        })

    })()

  }, [filters, setFilters, vibes])

  const cardsLoading = <CardSkeletons num={1} horizontal={false} />

  const topCards = cards.map(card => (
    <Suspense fallback={cardsLoading}>
      <LoadApiSingCard
        type={card.type}
        uuid={card.id}
        orientation="vertical"
        cardState={card.cardState}
        style={'basic'}
        isEvent={false}
        hasMapLink={true}
      />
    </Suspense>
  ))

  if (isLoading) {
    return cardsLoading
  }

  return (
   <>
    <ul>
      {topCards.map(card => <li>{card}</li>)}
    </ul>
   </>
  )
}

ActivitiesBlock.defaultProps = {
  numberOfCards: 40,
  searchTerm: null
}

//ActivitiesBlock.whyDidYouRender = true
export default pure(ActivitiesBlock)
